<template>
    <div>
        <v-card
            class="mx-auto d-flex flex-column mb-1"
            width="97%"
            flat
            @mouseenter="reveal = true"
            @mouseleave="reveal = false"
        >
            <v-list-item three-line class="card-header">
                <v-list-item-content class="pa-0">
                    <v-col cols="12" md="4" class="pa-0">
                        <div class="d-flex flex-space-between" :style="{'width': '100%'}">
                            <div class="d-flex flex-column">
                                <h3 :style="{color: colorCard}">{{item.startDt | pegaDiaDoMes}} {{ item.startDt | pegaMes}}</h3>
                                <h5 class="align" :style="{color: '#00000099'}">{{ item.startDt | pegaHoraEMin }}
                                    <span :style="{color: 'rgba(0, 0, 0, 0.6)', 'font-size': '0.55rem'}"> - {{ item.endDt | pegaHoraEMin }}</span>
                                </h5>
                            </div>
                            <template v-if="item.isEventOwner">
                                <v-divider vertical class="ml-4" :style="{'border': '1px dashed ' + colorCard}"></v-divider>
                                <v-divider vertical :style="{'border': '1px dashed ' + colorCard, 'margin-left': '2px'}"></v-divider>
                            </template>
                            <v-divider vertical class="ml-4" v-else :style="{'border': '1px solid ' + colorCard}"></v-divider>
                        </div>
                    </v-col>
                    <v-col cols="12" md="8" class="pa-0">
                        <div class="d-flex align-center justify-space-between">
                            <div>
                                <v-list-item-subtitle class="mb-1">
                                    {{ item.isEventOwner ? item.user.name : item.owner.name }}
                                </v-list-item-subtitle>
                                <h6 :style="{color: 'rgba(0, 0, 0, 0.6)'}">
                                    {{ item.isEventOwner ? item.user.role : item.owner.role }}
                                </h6>

                            </div>
                            <v-list-item-avatar size="50" color="grey">
                                <v-img :src="'data:image/jpg;base64, ' + (item.isEventOwner ? item.user.avatar : item.owner.avatar)"></v-img>
                            </v-list-item-avatar>

                        </div>
                    </v-col>
                </v-list-item-content>
            </v-list-item>
            <v-expand-transition>
                <div v-show="reveal">
                    <v-card
                        style="height: 100%;"
                    >
                        <v-card-text class="pb-0">
                            <div class="tabs" :class="{'executive-tab': item.isEventOwner, 'user-tab': !item.isEventOwner}">
                                <div class="d-flex px-1">
                                    <span class="tab" @click="currentTab = 'comments'" :class="{'active': currentTab == 'comments'}">Comentário</span>
                                    <span class="tab ml-3" @click="currentTab = 'about'" :class="{'active': currentTab == 'about'}">Sobre</span>
                                </div>
                                <div class="tab-content pa-2">
                                    <template v-if="currentTab == 'comments'">
                                        <h5>{{ item.comments }}</h5>
                                    </template>
                                    <template v-else>
                                        <h5 v-if="item.isEventOwner && item.user.about">{{ item.user.about }}</h5>
                                        <h5 v-else-if="!item.isEventOwner && item.owner.about">{{ item.owner.about }}</h5>
                                        <h5 :style="{'color': 'rgba(0, 0, 0, 0.35)'}" v-else>Esta pessoa não possui descrição.</h5>
                                    </template>
                                </div>
                            </div>
                        </v-card-text>
                        <v-card-actions class="flex-row justify-end">
                            <v-btn :color="item.isEventOwner ? 'orange' : 'accent'" text class="white--text" @click="dialog = true">
                                Desmarcar
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </div>
            </v-expand-transition>
        </v-card>
        <v-dialog v-model="dialog" persistent max-width="600px" >
            <v-card>
                <v-card-title>
                Desmarcar Cafezinho
                </v-card-title>
                <v-card-text>
                    <div>Deseja desmarcar seu cafezinho com <b>{{ item.isEventOwner ? item.user.name : item.owner.name }}</b> na data <b>{{ item.startDt | converteStringParaData }}?</b></div>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="flex-row justify-space-between">
                    <v-btn text @click="dialog = false">
                        Fechar
                    </v-btn>
                    <v-btn color="accent" text class="white--text" @click="dialog = false; cancelEvent(item)">
                        Desmarcar cafezinho
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    props: {
        item: {
            required: true,
        },
        cancelEvent: Function
    },
    data() {
        return {
            reveal: false,
            dialog: false,
            currentTab: "comments",
        }
    },
    computed: {
        colorCard() {
            return this.item.isEventOwner ? '#ff7800' : '#7500c0'
        },

    }
    
}
</script>

<style scoped>

.tab {
    font-size: 0.7rem;
    color: #b0bec5 !important;
    cursor: pointer;
    font-weight: bold;
}

.tab-content {
    text-align: justify;
}

.executive-tab .active {
    color: #ff7800 !important;
}

.user-tab .active {
    color: #7500c0 !important;
} 


</style>
