<template>
    <div class="d-flex flex-column dash-agendar-cafe" :style="{height: '100%'}">
        <div>
            <v-icon color="#460073" small>mdi-chevron-right</v-icon>
            <span class="title pl-2">Compartilhe ideias, hobbies e experiências com nossos gestores</span>
        </div>
        <div class="mt-4">
            <v-col sm="6" md="4" cols="12">
                <v-text-field v-model="inputString" @keyup.enter="searchExecutive()" aria-label="Digite aqui para pesquisar por nome" placeholder="Pesquisar por nome" prepend-inner-icon="mdi-magnify" color="#460073" filled dense ></v-text-field>
            </v-col>
        </div>
        <div class="d-flex flex-wrap justify-space-around flex-grow-1 align-start">
            <template v-if="skeletonAgendarCafezinho">
                <v-card v-for="n in 12" :key="n" >
                    <v-skeleton-loader
                        min-width="350"
                        type="list-item-avatar-two-line, actions"
                    ></v-skeleton-loader>
                </v-card>
            </template>
            <template v-else>
                <div :style="{width: '100%', height: '100%'}" class="d-flex justify-center" v-if="eventsByExecutive.length == 0">Nenhum resultado encontrado</div>
                <template v-else>
                    <v-col>
                        <ExecutivoCard  v-for="executive in arrayOne" :key="executive.id" :item="executive" @eventChanged="updateEvent(executive, $event)" />
                    </v-col>
                    <v-col>
                        <ExecutivoCard  v-for="executive in arrayTwo" :key="executive.id" :item="executive" @eventChanged="updateEvent(executive, $event)" />
                    </v-col>
                    <v-col>
                        <ExecutivoCard  v-for="executive in arrayThree" :key="executive.id" :item="executive" @eventChanged="updateEvent(executive, $event)" />
                    </v-col>
                </template>
            </template>
        </div>
        <div class="d-flex justify-center mb-4" :style="{width: '100%'}">
            <v-pagination
                color="accent"
                circle
                v-model="page"
                @input="getExecutivesByPage"
                :length="numPages"
                :total-visible="7"
            ></v-pagination>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ExecutivoCard from './ExecutivoCard'
export default {
    components: { ExecutivoCard },
    created() {
        this.fetchUsersByPage({page: 0}).then(() => {
            this.skeletonAgendarCafezinho = false
        })
    },
 
    data() {
        return {
            inputString: '',
            skeletonAgendarCafezinho: true
        }
    },
    computed: {
        ...mapState(['allExecutives', 'numPages', 'currentPage']),
        page: {
            get() {
                return this.currentPage
            },
            set(value) {
                this.updateCurrentPage(value)
            }
        },
        eventsByExecutive(){
            if(!this.allExecutives)
                return []

            return this.allExecutives
        },
        arrayOne() {
            return this.eventsByExecutive.filter((el, index) => index % 3 == 0)
        },
        arrayTwo() {
            return this.eventsByExecutive.filter((el, index) => index % 3 == 1)
        },
        arrayThree() {
            return this.eventsByExecutive.filter((el, index) => index % 3 == 2)
        },
        account() {
            return this.$store.state.currentUser
        }
    },
    methods: {
        ...mapActions(['fetchUsers', 'fetchUsersByPage', 'getNumPages', 'searchExecutives', 'updateCurrentPage']),
         updateEvent(exec, event){
             let executive = this.allExecutives[this.allExecutives.findIndex(e => e.id == exec.id)];
 
             this.$set(executive.eventsOwner, executive.eventsOwner.findIndex(e => e.id == event.id), event)
        },
        async getExecutivesByPage() {
            this.skeletonAgendarCafezinho = true

            await this.fetchUsersByPage({page: this.page - 1, searchString: this.inputString})
            
            this.skeletonAgendarCafezinho = false
        },
        async searchExecutive() {
            this.skeletonAgendarCafezinho = true

            await this.fetchUsersByPage({page: 0, searchString: this.inputString})
            this.page = 1
            
            this.skeletonAgendarCafezinho = false
        }
    }
}
</script>

<style>
.dash-agendar-cafe {
    overflow-y: auto;
    height: 100vh;
}
</style>