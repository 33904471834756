<template>
  <div class="d-flex mt-6 justify-space-between">
    <v-card
        class="d-flex flex-column justify-end flex-grow-1"
        min-width="350"
        elevation="3"
        :disabled="item.email == account.email"
        aria-label="Informações do executivo"
        tabindex="0"
    >
      <v-list-item three-line class="card-header">
        <v-list-item-avatar
          v-if="item.avatar"
          rounded
          class="rounded-lg"
          size="60"
          color="grey"
          aria-label="Foto de perfil" tabindex="0"
        >
          <v-img :src="'data:image/jpg;base64, ' + item.avatar"></v-img>
        </v-list-item-avatar>
        <v-list-item-content class="align-start">
            <v-list-item-title class="headline mb-1 card-title" :aria-label="`Nome do executivo ${item.name}`" tabindex="0">
                {{ item.name }}
            </v-list-item-title>
            <v-list-item-subtitle v-if="item.role" class="card-subtitle" :aria-label="`Cargo do executivo ${item.role}`" tabindex="0">
                {{ item.role }}
            </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-card-actions aria-label="Três próximos horários disponibilizados pelo executivo">
        <div :style="{ width: '100%' }" class="d-flex flex-wrap">
          <div class="d-flex flex-wrap" v-if="!show">
            <v-chip
              v-for="(schedule, i) in nextThreeEvents"
              :key="i"
              class="mx-1"
              label
              outlined
              @click="openSlotDialog(schedule)"
              @keydown.enter="openSlotDialog(schedule)"
              :disabled="schedule.isBusy || !schedule.processed"
              tabindex="0"
              :aria-disabled="schedule.isBusy || !schedule.processed"
              :aria-label="textoDataCompleta(schedule.startDt)"
            >
              <h5 :style="{ color: '#7500c0' }">    
                {{ schedule.startDt | pegaDiaDoMes }}
                {{ schedule.startDt | pegaMes }}
              </h5>
              <h6 class="align-center ml-1" :style="{ color: '#00000099' }">
                {{ schedule.startDt | pegaHoraEMin }}
              </h6>
            </v-chip>
          </div>
          <div class="flex-grow-1 d-flex justify-end pa-0">
            <v-btn
              aria-label="Expandir informações"
              icon
              @click="show = !show"
              :aria-expanded="show"
            >
              <v-icon>{{
                show ? "mdi-chevron-up" : "mdi-chevron-down"
              }}</v-icon>
            </v-btn>
          </div>
        </div>
      </v-card-actions>
      <v-expand-transition>
        <div v-if="show">
          <v-hover>
            <template v-slot:default="{ hover }">
              <v-card
                width="100%"
                class="mx-auto"
                color="#e6e6dc"
                flat
                tile
                @keydown.enter="aboutDialog = true" 
                tabindex="0"
                :aria-label="`Descrição pessoal de ${item.name} ${item.about}`"
              >
                <v-card-text
                  :style="{ 'text-align': 'justify', 'font-size': '0.73rem' }"
                  class="font-weight-bold"
                >
                  <span class="about" v-if="item.about">
                    <v-icon left>mdi-comment-quote</v-icon>
                    {{ item.about }}
                  </span>
                  <h4
                    v-else
                    :style="{
                      color: 'rgba(0, 0, 0, 0.4)',
                      'align-self': 'center',
                    }"
                  >
                    Esta pessoa não possui descrição.
                  </h4>
                </v-card-text>
                <v-fade-transition>
                  <v-overlay v-if="hover && item.about" absolute color="black">
                    <v-btn
                      color="accent"
                      aria-label="Ver descrição do executivo"
                      aria-haspopup
                      @click="aboutDialog = true"
                      >Ver mais</v-btn
                    >
                  </v-overlay>
                </v-fade-transition>
              </v-card>
            </template>
          </v-hover>
          <v-carousel
            hide-delimiter-background
            hide-delimiters
            height="auto"
            class="d-flex flex-grow-1"
            max="4"
          >
            <v-carousel-item
              v-for="n in numPages(eventsForTheNextMonth)"
              :key="n"
            >
              <v-sheet light class="px-12" aria-label="Carrossel de horários. Passe para frente para ver mais." >
                <v-container>
                  <div class="d-flex flex-wrap justify-space-between align-end" 
                    aria-label="Horários disponibilizados pelo executivo nos próximos 30 dias" 
                    aria-colcount="2" aria-rowcount="2"
                    aria-valuemin="1" :aria-valuemax="numPages(eventsForTheNextMonth)"
                    :aria-valuenow="n">
                    <v-chip
                      v-for="(schedule, i) in eventsByPage(n - 1)"
                      :key="i"
                      class="ma-1"
                      label
                      outlined
                      @click="openSlotDialog(schedule)"
                      @keydown.enter="openSlotDialog(schedule)"
                      :disabled="schedule.isBusy || !schedule.processed"
                      :aria-disabled="schedule.isBusy || !schedule.processed"
                      :aria-label="textoDataCompleta(schedule.startDt)"
                    >
                      <h5 :style="{ color: '#7500c0' }">
                        {{ schedule.startDt | pegaDiaDaSemana }},
                        {{ schedule.startDt | pegaDiaDoMes }}
                        {{ schedule.startDt | pegaMes }}
                      </h5>
                      <h6
                        class="align-center ml-1"
                        :style="{ color: '#00000099' }"
                      >
                        {{ schedule.startDt | pegaHoraEMin }}
                      </h6>
                    </v-chip>
                  </div>
                </v-container>
              </v-sheet>
            </v-carousel-item>
          </v-carousel>
        </div>
      </v-expand-transition>
    </v-card>
    <v-dialog
      v-model="aboutDialog"
      max-width="600px"
      persistent
      aria-modal
      :aria-label="`Descrição pessoal de ${item.name}`"
    >
      <v-card>
        <v-card-title tabindex="0">Sobre {{ item.name }}</v-card-title>
        <v-card-text tabindex="0">
          <v-container :style="{ 'text-align': 'justify' }">
            {{ item.about }}
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn text @click="aboutDialog = false">
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title tabindex="0">
          Você está agendando um cafezinho
        </v-card-title>
        <v-card-text>
          <v-container>
            <div tabindex="0"><b>Nome do executivo:</b> {{ item.name }}</div>
            <div v-if="selectedSchedule">
              <div tabindex="0">
                <b>Data | Horário:</b>
                {{ selectedSchedule.startDt | pegaDiaDaSemana }},
                {{ selectedSchedule.startDt | pegaDiaDoMes }} de
                {{ selectedSchedule.startDt | pegaMes }} |
                {{ selectedSchedule.startDt | pegaHoraEMin }} -
                {{ selectedSchedule.endDt | pegaHoraEMin }}
              </div>
            </div>
            <v-form v-model="form">
              <v-textarea
                class="mt-5 caption"
                name="input-7-1"
                :rules="textAreaRules"
                label="Comentário (opcional)"
                v-model="comment"
                outlined
                color="dark"
              ></v-textarea>
            </v-form>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="flex-row justify-space-between">
          <v-btn
            text
            @click="
              dialog = false;
              resetComment();
            "
          >
            Cancelar
          </v-btn>
          <v-btn
            text
            color="accent"
            class="white--text"
            @click="
              dialog = false;
              confirmSchedule();
            "
            :disabled="!form"
          >
            Reservar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
// import FilterContainer from './searchAndFilter/FilterContainer.vue'

import { mapActions } from "vuex";
import Vue from 'vue'


export default {
  props: {
    item: {
      required: true,
    },
  },
  data() {
    return {
      show: false,
      pageSize: 4,
      dialog: false,
      selectedSchedule: undefined,
      comment: "Estou ansioso para o nosso cafezinho. Até lá.",
      aboutDialog: false,
      textAreaRules: [(v) => v.length <= 200 || "Max 200 caracteres"],
      form: false,
    };
  },
  created() {
	Vue.prototype.$filters = Vue.options.filters

  },
  computed: {
    eventsForTheNextMonth() {
      const today = new Date();
      const lastDate = new Date();
      lastDate.setDate(today.getDate() + 30);

      return this.item.eventsOwner.filter((e) => {
        const startDate = new Date(e.startDt);
        return startDate >= today && startDate <= lastDate;
      });
    },
    nextThreeEvents() {
      return this.eventsForTheNextMonth.slice(0, 3);
    },
    account() {
      return this.$store.state.currentUser;
    },
  },
  methods: {
    ...mapActions(["assignUserToEvent"]),
    confirmSchedule() {
      this.assignUserToEvent({
        ...this.selectedSchedule,
        Comments: this.comment,
      })
        .then((res) => {
          this.$emit("eventChanged", res.data.evUpdated);
        })
        .catch(() => {});
    },
    openSlotDialog(schedule) {
      if(schedule.isBusy || !schedule.processed) {
        return;
      }
      this.dialog = true; 
      this.selectedSchedule = schedule;
    },
    eventsByPage(currentPage) {
      let eventsDisplay = new Array();
      for (let i = 0; i < this.pageSize; i++) {
        const offset = currentPage * this.pageSize;

        if (i + offset >= this.eventsForTheNextMonth.length) break;

        eventsDisplay.push(this.eventsForTheNextMonth[i + offset]);
      }
      return eventsDisplay;
    },

    numPages(arrSize) {
      let nPages = Math.trunc(arrSize.length / this.pageSize);

      if (arrSize.length % this.pageSize > 0) nPages += 1;

      return nPages;
    },

    resetComment() {
      this.comment = "Estou ansioso para o nosso cafezinho. Até lá.";
    },
	textoDataCompleta(startDt) {
		return `${Vue.options.filters.pegaDiaDaSemanaCompleto(
                  startDt
                )}, ${Vue.options.filters.pegaDiaDoMes(
                  startDt
                )} de ${Vue.options.filters.pegaMesCompleto(
                  startDt
                )} às ${Vue.options.filters.pegaHoraEMin(startDt)}`
	}
  },
};
</script>

<style scoped>
.v-carousel__controls {
  display: none !important;
}

.v-card > *:last-child {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.card-header {
  display: flex;
  flex-grow: 0;
  flex-basis: auto;
}

.card-title {
  font-size: 1rem !important;
}

.card-subtitle {
  font-size: 0.75rem !important;
}

::v-deep .v-carousel > .v-window__container {
  width: 100% !important;
}

.about {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
