<template>
    <v-app app>
        <div :style="{width: '100%', height: '100%'}" class="d-flex justify-center align-center">
            <v-progress-circular
                :size="70"
                :width="7"
                color="accent"
                indeterminate
            ></v-progress-circular>
        </div>
    </v-app>
</template>

<script>
export default {

}
</script>

<style>

</style>