import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
import authClient from './authentication/authClient'
import vuetify from './plugins/vuetify'
import Emitter from 'tiny-emitter'

import toast from "vue-toastification"
import 'vue-toastification/dist/index.css'
import './styles/custom-toast.css'


// new Accessibility(options);

Vue.prototype.$msalInstance = authClient;

Vue.prototype.$emitter = new Emitter()

Vue.config.productionTip = false

Vue.filter('converteDataParaDiaDaSemana', function(value) {
    const days = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado']
    var date = new Date(value)
    return days[date.getDay()]
})

Vue.filter('pegaDiaDoMes', function(value) {
    var date = new Date(value)
    return date.getDate()
})

Vue.filter('pegaMes', function(value) {
    const months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
    var date = new Date(value)
    return months[date.getMonth()]
})

Vue.filter('pegaMesCompleto', function(value) {
    const months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
    var date = new Date(value)
    return months[date.getMonth()]
})

Vue.filter('pegaDiaDaSemana', function(value) {
    const week = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'];
    var date = new Date(value)
    return week[date.getDay()]
})

Vue.filter('pegaDiaDaSemanaCompleto', function(value) {
    const week = ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'];
    var date = new Date(value)
    return week[date.getDay()]
})

Vue.filter('pegaHoraEMin', function(value) {
    var date = new Date(value)
    return date.getHours().toString().padStart(2, '0') + ":" + date.getMinutes().toString().padStart(2, '0')
})


Vue.filter('converteStringParaData', function (value) {
    // var date = new Date(value).toLocaleString("BR", { timeZone: "America/Sao_Paulo" })
    var brDate = new Date(value);
    return brDate.toLocaleString("pt-br", { weekday: 'short', day: '2-digit', month: 'short', hour: '2-digit', minute: '2-digit' })
})

Vue.use(toast, {
    transition: "my-custom-fade",
    hideProgressBar: true,
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
