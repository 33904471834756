<template>
   <v-app app>
       <Load v-if="load" />
        <div v-else class="d-flex container-new-user">
            <div class="screen-left">
                <div class="opacity"></div>
            </div>
            <div class="screen-right d-flex flex-column align-center justify-center">
                <div>
                    <h1 class="mt-4 main-title">HORA DO CAFEZINHO</h1>
                    <h4 class="mt-4 pl-1" :style="{color: '#ffeb32'}">É hora de compartilhar ideias, hobbies, aspirações e experiências!</h4>
                </div>
                <!-- <h3 class="mt-4" :style="{color: 'white'}">Seja bem-vind@, {{ account.name }}</h3> -->
                <div class="mt-4 d-flex align-center justify-center align-center">
                    <v-carousel
                        hide-delimiter-background
                        hide-delimiters
                        height="auto"
                        class="d-flex flex-grow-1"
                        :style="{ 'align-items': 'center', width: '100%' }"
                        v-model="currentPage"
                        :show-arrows="false"
                    >
                        <v-carousel-item>
                            <v-sheet color="accent">
                                <v-container :style="{ width: '100%', height: '100%' }">
                                    <v-card class="mx-auto" color="accent" flat tile>
                                        <v-card-subtitle class="white--text">
                                            <v-icon large left color="white">mdi-comment-quote</v-icon>
                                            Para que as pessoas possam te conhecer melhor, escreva uma pequena descrição sobre você.
                                        </v-card-subtitle>

                                        <v-card-text class="caption black--text">
                                            <v-form v-model="form">
                                                <v-textarea class="mt-5 text-area-about"
                                                    name="input-7-1"
                                                    :placeholder="'Um pouquinho sobre ' + account.name"
                                                    outlined
                                                    filled
                                                    :rules="textAreaRules"
                                                    v-model="about"
                                                ></v-textarea>
                                            </v-form>
                                        </v-card-text>
                                    </v-card>
                                </v-container>
                            </v-sheet>
                        </v-carousel-item>
                        <!-- <v-carousel-item >
                            <v-sheet color="accent">
                            <v-container :style="{ width: '100%', height: '100%' }">
                                <v-card class="mx-auto" color="accent" flat tile>
                                    <v-card-subtitle class="white--text">
                                        <v-icon large left color="white">mdi-gamepad-variant</v-icon>
                                        Para que as pessoas possam te conhecer melhor, conte seus hobbies.
                                    </v-card-subtitle>

                                    <v-card-text class="caption ">
                                        <v-textarea class="mt-5"
                                        name="input-7-1"
                                        v-model="comment"
                                        :placeholder="'Sobre o/a ' + account.name"
                                        outlined
                                        filled
                                    ></v-textarea>
                                    </v-card-text>
                                </v-card>
                            </v-container>
                            </v-sheet>
                        </v-carousel-item> -->
                    </v-carousel>
                </div>
                <!-- <div class="d-flex justify-space-around" :style="{width: '70%'}">
                    <v-btn text color="secondary" :disabled="currentPage == 0" @click="currentPage -= 1">Voltar</v-btn>
                    <v-divider vertical :style="{'border-color': 'rgba(255, 255, 255, 0.35)'}"></v-divider>
                    <template>
                        <v-btn text color="warn" v-if="currentPage < numPage - 1" :disabled="currentPage >= numPage - 1" @click="currentPage += 1">Próximo</v-btn>
                        <v-btn text color="warn" v-else >Concluir</v-btn>

                    </template>
                </div> -->
                <div class="d-flex justify-space-around" :style="{width: '70%'}">
                    <v-btn text color="white" class="skip" @click="registerNewUser(true)">Preencher mais tarde</v-btn>
                    <v-divider vertical :style="{'border-color': 'rgba(255, 255, 255, 0.35)'}"></v-divider>
                    <v-btn text color="warn" class="register" @click="registerNewUser(false)" :disabled="!form">Concluir cadastro</v-btn>
                </div>
            </div>
       </div>
   </v-app>
</template>

<script>
import { mapActions } from 'vuex'
import Load from './Load.vue'
export default {
    components: { Load },
    data() {
        return {
            next: false,
            currentPage: 0,
            numPage: 1,
            about: null,
            load: false,
            textAreaRules: [v => v.length <= 500 || 'Max 500 caracteres'],
            form: false,
        }
    },
    computed: {
        account() {
            return this.$msalInstance.getActiveAccount()
        }
    },

    methods: {
        ...mapActions(['addUser']),
        registerNewUser(skip) {
            if(skip)
                this.about = null
            
            this.load = true
            this.addUser(this.about).then(() => {
                this.load = false

            })
        }
    }
}
</script>

<style scoped>
@font-face {
    font-family: 'Graphik';
    src: url('../../public/Graphik-Bold.ttf');
}
.container-new-user {
    height: 100% !important;
    width: 100%;
}

.screen-left {
    width: 35vw;
    height: 100% !important;
    background-image: url('../assets/TeamsBG-WalkinCloset.jpg');
    background-size: auto;
    background-position-x: center;
}

.screen-right {
    height: 100%;
    width: 65vw;
    background-color: #7500c0;
}

.opacity {
    height: 100%;
    width: 100%;
    background-color: rgba(117, 0, 192, 0.3);
}

.main-title {
    font: normal normal bold 60px Graphik;
    color: white;
}

.text-area-about >>> .error--text, .text-area-about.error--text {
    color: #ffeb32 !important;
    caret-color: #ffeb32 !important;
}

</style>