import http from '@/services'
import Vue from 'vue'

export const allExecutives = data => {
    
    return http.get('User/AllExecutives', data, { headers: {'Content-Type': 'application/json'}})
} 
// .then(data => {
//     data.map(e => ({
//         ...e, about: e.about || null
//     }))
// })
export const executivesByPage = (page, searchString) => {
    return http.get('User/ExecutivesByPage', {params: { page: page, searchString: searchString }})
}

export const myEvents = () => http.get('User/CurrentUserEvents')

export const mySchedules = () => http.get('User/UserSchedules')

export const addNewUser = data => {
    return http.post('User/AddNewUser', JSON.stringify(data), { headers: {'Content-Type': 'application/json'} })
} 

export const updateAbout = data => {
    return http.post('User/UpdateAbout', JSON.stringify(data), { headers: {'Content-Type': 'application/json'} }).catch(() => {
        Vue.$toast.error("Erro ao atualizar descrição")
    })
} 

export const getCurrentUser = () => http.get('User/Current')
