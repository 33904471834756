<template>
    <v-navigation-drawer
        v-model="drawer"
        src="../../assets/canecacafe.gif"
        permanent
        app
    >
        <v-list-item class="d-flex flex-column justify-center align-center item-user">
            <v-list-item-avatar size="65" :style="{'box-shadow': '0 0 0 3px white'}">
                <v-img :src="'data:image/jpg;base64, ' + account.avatar"></v-img>
            </v-list-item-avatar>

            <v-list-item-title>{{ account.name }}</v-list-item-title>
        </v-list-item>
        <!-- <v-divider></v-divider> -->
        <v-hover>
            <template v-slot:default="{ hover }">
                <v-list-item class="mt-2 pa-4" :style="{'background-color': 'white', 'white-space': 'initial'}" id="teste" @keydown.enter="dialog = true" tabindex="0">
                    <v-list-item-content class="list-about">
                        <v-icon left color="accent">mdi-comment-quote</v-icon>
                        <template v-if="account.about">{{ account.about }}</template>
                        <template v-else>
                            <span class="font-italic grey--text">Você ainda não possui descrição. Clique aqui para editar.</span> 
                        </template>
                    </v-list-item-content>
                    <v-fade-transition>
                        <v-overlay
                            v-if="hover"
                            absolute
                            color="black"
                        >
                            <v-btn color="accent" @click="dialog = true">Editar</v-btn>
                        </v-overlay>
                    </v-fade-transition>
                </v-list-item>
            </template>
        </v-hover>
        
        <v-divider></v-divider>
        <v-list>
            <v-list-item @click="SignOut()">
                <v-list-item-content>
                    Sair da conta
                </v-list-item-content>
                <v-list-item-icon>
                    <v-icon>mdi-logout-variant</v-icon>
                </v-list-item-icon>
            </v-list-item>
        </v-list>
        <v-dialog v-model="dialog" persistent max-width="600px" >
            <v-card>
                <v-card-title>
                    Conte um pouquinho sobre você
                </v-card-title>
                <v-card-text>
                <v-container>
                    <v-form v-model="form">
                        <v-textarea class="mt-5 caption"
                            :rules="textAreaRules"
                            name="input-7-1"
                            label="Sobre mim"
                            v-model="aboutModel"
                            outlined
                            color="dark"
                        ></v-textarea>
                    </v-form>
                </v-container>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="flex-row justify-space-between">
                    <v-btn text @click="dialog = false; resetAbout()">
                        Cancelar
                    </v-btn>
                    <v-btn text color="accent" class="white--text" @click="dialog = false; setAbout()" :disabled="!form">
                        Salvar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-navigation-drawer>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    data () {
        return {
            drawer: true,
            items: [
            { title: 'Marcar um cafezinho', icon: 'mdi-coffee', route: "/" },
            { title: 'Meus cafezinhos', icon: 'mdi-account', route: "MeusCafezinhos" },
            //   { title: 'Users', icon: 'mdi-account-group-outline', route: "/login" },
            ],
            mini: false,
            dialog: false,
            newAbout: '',
            textAreaRules: [v => v.length <= 500 || 'Max 500 caracteres'],
            form: false,
            aboutModel: this.$store.state.currentUser.about
            
        }
    },
    computed: {
        account() {
           return this.$store.state.currentUser
        },
    },
    methods: {
        ...mapActions(['setIsAuthenticated', 'updateAboutMe']),
        async SignOut() {
            await this.$msalInstance
            .logout()
            .then(() => {
            })
            .catch(error => {
                console.error(error);
            });
        },
        setAbout() {
            console.log(this.aboutModel)
            this.updateAboutMe(this.aboutModel)
        },
        resetAbout() {
            this.aboutModel = this.$store.state.currentUser.about
        }
    }
}
</script>

<style>

.v-navigation-drawer__image {
    height: 50% !important;
    /* position: relative !important; */
    bottom: 0  !important;
    top: unset  !important;
    
}

.v-navigation-drawer__content {
    background: linear-gradient(rgba(255,255,255,1),rgba(255,255,255,1),rgba(255,255,255,1), rgba(255,255,255,0.3), rgba(255,255,255,0));
}

.list-about {
    color: black;
    font-size: 0.7rem;
    line-height: 1.255rem;
    letter-spacing: 0.0071428571em;
    text-align: justify;
}
.item-user {
    background: linear-gradient(to bottom, #7500c0 0% 50%, white 50% 100%);
    
}

.item-user::after {
    display: none;
}
</style>