import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#a760f5',
                secondary: '#b0bec5',
                accent: '#7500c0',
                error: '#ff3246',
                success: '#05f0a5',
                dark: '#460073',
                warn: '#ffeb32',
                orange: '#ff7800'
            },
            dark: {
                accent: '#460073',
            },
        },
    },
});
