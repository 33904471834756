<template>
    <v-card class="mb-4 ml-2" outlined>
        <v-card-text>
            <div class="d-flex flex-space-around" :style="{'width': '100%'}">
                <div class="d-flex flex-column mb-2">
                    <h4 :style="{color: 'rgba(0, 0, 0, 0.4)'}"><h5 :style="{color: 'rgba(0, 0, 0, 0.4)'}">{{day | pegaDiaDaSemana}},</h5> {{day | pegaDiaDoMes}} {{ day | pegaMes}}</h4>
                </div>
            </div>
            <div class="d-flex flex-wrap">
                <span v-for="schedule, i in schedules" :key="schedule.id" >
                    <v-hover>
                        <template v-slot:default="{ hover }">
                            <v-chip label color="accent" class="mr-2 mb-2" link @click="toggleDialog(i)">
                                {{ schedule.startDt | pegaHoraEMin }}
                                <v-fade-transition>
                                    <v-overlay
                                        v-if="hover"
                                        absolute
                                        color="black"
                                    >
                                        <v-icon>mdi-close</v-icon>
                                    </v-overlay>
                                </v-fade-transition>
                            </v-chip>
                        </template>
                    </v-hover>
                    <v-dialog v-model="dialog[i]" persistent max-width="600px" >
                        <v-card>
                            <v-card-title>
                                Cancelar horário
                            </v-card-title>
                            <v-card-text>
                                <div>Deseja cancelar seu horário na data <b>{{ schedule.startDt | converteStringParaData }}?</b></div>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-actions class="flex-row justify-space-between">
                                <v-btn text @click="toggleDialog(i)">
                                    Fechar
                                </v-btn>
                                <v-btn color="accent" text class="white--text" @click="toggleDialog(i); cancelSchedule(schedule)">
                                    Desmarcar horário
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </span>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>

export default {
    props: {
        day: {
            required: true,
        },
        schedules: {
            required: true,
        },
        cancelSchedule: Function
    },
    data() {
        return {
            dialog: this.schedules.map(() => false)
        }
    },
    methods: {
        toggleDialog(position) {
            this.$set(this.dialog, position, !this.dialog[position])
        }
    }
}
</script>

<style>

</style>