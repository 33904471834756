
import { PublicClientApplication } from '@azure/msal-browser'

export default {
    msalInstance: new PublicClientApplication({
        auth: {
            clientId: process.env.VUE_APP_AUTH_CLIENT_ID,
            authority: `https://login.microsoftonline.com/${process.env.VUE_APP_AUTH_TENANT}/`,
            redirectUri: process.env.VUE_APP_URL_FRONTEND,
            postLogoutRedirectUri: process.env.VUE_APP_URL_FRONTEND
        },
        cache: {
            cacheLocation: "localStorage", // This configures where your cache will be stored
            storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
        },
        system: {
            loggerOptions: {	
                loggerCallback: (level, message, containsPii) => {
                    if (containsPii) {
                        return
                    }
                    console.warn(message)
                    return
                }
            }
        }
    }),

    getActiveAccount() {
        if(this.msalInstance.getActiveAccount())
            return this.msalInstance.getActiveAccount()

        if(this.msalInstance.getAllAccounts()[0])
            return this.setActiveAccount()

        return undefined;
    },

    loggedIn() {
        return this.getActiveAccount()
    },

    logout() {
        return this.msalInstance.logoutRedirect()
    },

    setActiveAccount(account)  {
        this.msalInstance.setActiveAccount(account)
        
        return account;
    },
    
    async login() {
        await this.msalInstance.handleRedirectPromise().then(tokenResponse => {
            if (tokenResponse !== null) { //quando vem do redirect do login
                this.msalInstance.setActiveAccount(tokenResponse.account);
            } else { //abrir o site
                const currentAccounts = this.msalInstance.getAllAccounts();
                if (!currentAccounts || currentAccounts.length < 1) { //nunca fez login
                    return;
                } else if (currentAccounts.length === 1) { //ja ta logada
                    const activeAccount = currentAccounts[0];
                    this.msalInstance.setActiveAccount(activeAccount);
                }
            }
        })

        if (this.loggedIn())
            return;

        await this.msalInstance.loginRedirect()
    },

    async getToken() {
        const request = {
            scopes: [`api://${process.env.VUE_APP_AUTH_CLIENT_ID}/user.read`],
            account: this.msalInstance.getAllAccounts()[0]
        }
        try {
            const token = await this.msalInstance.acquireTokenSilent(request).catch(err => {
                console.log("erro dentro do acquireTokenSilent", err)
            })
            
            this.setActiveAccount(token.account)
            return token.accessToken
        } catch (err) {
            this.msalInstance.acquireTokenRedirect(request)
        }
    }
}