import Vue from 'vue'
import Vuex from 'vuex'
import { userService, eventService } from '@/services';
//import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
    // plugins: [createPersistedState( {
    //     storage: window.localStorage,
    // })],
    state: {
        allExecutives: [],
        myEvents: {
            eventsOwner: [],
            eventsUser: []
        },
        mySchedules: {
            userSchedules: []
        },
        eventChanged: undefined,
        ui: {
            error: '',
        },
        currentUser: undefined,
        numPages: 0,
        currentPage: 1
    },
    mutations: {
        setUsers(state, users) {
            state.allExecutives = users;
        },
        setNumPages(state, nPages) {
            state.numPages = nPages;
        },
        setCurrentPage(state, page) {
            state.currentPage = page;
        },
        setErrorMessage(state, error) {
            state.ui.error = error;
        },
        setMyEvents(state, evs) {
            state.myEvents = evs;
        },
        setMySchedules(state, evs) {
            state.mySchedules = evs;
        },
        currentUser(state, currentUser) {
            state.currentUser = currentUser;
        },
        
    },
    actions: {
        async fetchUsers({ commit }) {
            const users = await userService.allExecutives() // <<< uses the Service Layer to call the API
            commit('setUsers', users.data)
        },
        async fetchUsersByPage({ commit }, {page: page, searchString: searchString}) {
            const result = await userService.executivesByPage(page, searchString) // <<< uses the Service Layer to call the API
            commit('setUsers', result.data.executives)
            commit('setNumPages', result.data.numPages)
        },
        setErrorMessage({ commit }, { error }) {
            commit('setErrorMessage', { error })
        },
        assignUserToEvent({ commit }, data) {
            return eventService.assignUserToEvent(data).then(res => {
                commit('setMyEvents', { eventsUser: res.data.eventsUser, eventsOwner: res.data.eventsOwner})
                return res
            }).catch(err => {
                throw err
            })
           
        },
        async getMyEvents({commit}) {
            const events = await userService.myEvents()
            commit('setMyEvents', events.data)
        },
        async getMySchedules({commit}) {
            const schedules = await userService.mySchedules()
            commit('setMySchedules', schedules.data)
        },
        async cancelEventById(context, data) {
            const res = await eventService.cancelEvent(data);
            return res      
        },
        async cancelScheduleById(context, data) {
            const res = await eventService.cancelSchedule(data);
            return res
        },
        async addUser({commit}, about) {
            const newUser = await userService.addNewUser(about)
            commit('currentUser', newUser.data)
        },
        async currentUser({commit}) {
            const request = await userService.getCurrentUser()
            if(request.status == '204')
                commit('currentUser', null)
            else
                commit('currentUser', request.data)
        },
        async updateAboutMe({commit}, newAbout) {
            const newUser = await userService.updateAbout(newAbout)
            commit('currentUser', newUser.data)
            return newUser
        },
        async updateCurrentPage({commit}, page) {
            commit('setCurrentPage', page)
        },
    },
  
})
