<template>
    <div class="dash-agenda">
        <template v-if="account.isExecutive">
            <v-tabs v-model="tab" fixed-tabs color="dark">
                <v-tab href="#tab-meuscafezinhos" light>Meus cafezinhos</v-tab>
                <v-tab href="#tab-meushorarios">Meus horários</v-tab>
                <v-tabs-slider color="dark"></v-tabs-slider>
            </v-tabs>
            <v-tabs-items v-model="tab" class="tab-items">
                <v-tab-item value="tab-meuscafezinhos">
                    <div class="d-flex flex-column mt-6" v-if="skeletonMeusCafezinhos">
                        <v-skeleton-loader
                            type="text"
                        ></v-skeleton-loader>
                        <v-skeleton-loader
                            :style="{'transform': 'scaleX(-1)'}"
                            v-for="n in 9" :key="n"
                            min-width="350"
                            type="list-item-avatar-two-line"
                        ></v-skeleton-loader>
                    </div>
                    <template v-else>
                        <template v-if="myEvents.length">
                            <div class="d-flex justify-center mt-6">
                                <span :style="{'text-align': 'center'}">
                                    <v-icon color="accent">mdi-minus</v-icon>
                                    <h5 class="ml-1">Sou participante</h5>
                                </span> 
                                <span class="ml-3" :style="{'text-align': 'center'}">
                                    <v-icon color="orange">mdi-drag-horizontal</v-icon>
                                    <h5 class="ml-1">Sou dono</h5>
                                </span> 
                            </div>
                            <div class="pa-4 d-flex flex-column">
                                <MeusCafezinhos v-for="event in myEvents" :key="event.id" :item="event" :cancelEvent="cancelSelectedEvent" />
                            </div>
                        </template>
                        <div v-else class="pa-4 mt-4">Você não possui cafezinhos agendados</div>
                    </template>
                </v-tab-item>
                <v-tab-item value="tab-meushorarios" class="pa-2 mt-4">
                    <div class="d-flex flex-wrap mt-6" v-if="skeletonMeusHorarios">
                        <v-card class="mb-4 ml-2" v-for="n in 9" :key="n" outlined>
                            <v-skeleton-loader
                                type="card-heading, actions"
                            ></v-skeleton-loader>
                        </v-card>
                    </div>
                    <template v-else>
                        <div v-if="mySchedules.length" class="d-flex flex-wrap">
                            <MeusHorarios v-for="(events, date) in mySchedulesDates" :key="date" :day="date" :schedules="events" :cancelSchedule="cancelSelectedScheduleById" />
                        </div>
                        <div v-else>Você ainda não cadastrou horários para os próximos 30 dias</div>
                    </template>
                </v-tab-item>
            </v-tabs-items>
        </template>
        <template v-else>
            <div>
                <v-icon color="#460073" small>mdi-chevron-right</v-icon>
                <span class="title pl-2">Meus cafezinhos</span>
            </div>
            <div class="d-flex flex-column mt-6" v-if="skeletonMeusCafezinhos">
                <v-skeleton-loader
                    :style="{'transform': 'scaleX(-1)'}"
                    v-for="n in 9" :key="n"
                    min-width="350"
                    type="list-item-avatar-two-line"
                ></v-skeleton-loader>
            </div>
            <template v-else>
                <div class="mt-8 pa-4" v-if="myEvents.length">
                    <MeusCafezinhos v-for="event, i in myEvents" :key="i" :item="event" :cancelEvent="cancelSelectedEvent" />
                </div>
                <div v-else class="pa-4 mt-4">Você não possui cafezinhos agendados</div>
            </template>
        </template>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import MeusCafezinhos from './MeusCafezinhos.vue'
import MeusHorarios from './MeusHorarios.vue'
export default {
    created() {
        this.getMyEvents().then(() => {
            this.skeletonMeusCafezinhos = false
        })
        this.getMySchedules().then(() => {
            this.skeletonMeusHorarios = false
        })
    },
    components: { MeusCafezinhos, MeusHorarios },
    data() {
        return {
            tab: 'tab-meuscafezinhos',
            skeletonMeusCafezinhos: true,
            skeletonMeusHorarios: true
        }
    },
    computed: {
        myEvents() {
            if(!this.$store.state.myEvents.eventsUser && !this.$store.state.myEvents.eventsOwner) {
                return []
            }
            return [...this.$store.state.myEvents.eventsUser.map(e => ({...e, isEventOwner: false})), ...this.$store.state.myEvents.eventsOwner.map(e => ({...e, isEventOwner: true}))]
        },
        mySchedules() {
            if(!this.$store.state.mySchedules.userSchedules) {
                return []
            }

            return this.$store.state.mySchedules.userSchedules
        },
        mySchedulesDates() {
            let dates = {};

            for(let event of this.mySchedules)
            {
                let dateStr = new Date(event.startDt).toDateString();
                
                if (dateStr in dates)
                    dates[dateStr].push(event)
                else
                    dates[dateStr] = [event];
            }

            return dates
        },
        account() {
            return this.$store.state.currentUser
        }
    },
    methods: {
        ...mapActions(['getMyEvents', 'cancelEventById', 'getMySchedules', 'cancelScheduleById', 'fetchUsersByPage']),
        cancelSelectedEvent(ev) {
            this.cancelEventById(ev.id).then(() => {
                this.getMyEvents()
                this.$emit('updateExecutives')
            }).catch(() => {})          
        },
        cancelSelectedScheduleById(ev) {
            this.cancelScheduleById(ev.id).then(() => {
                this.getMySchedules()
            }).catch(() => {})
           
        },
        filterByDate(date) {
            const dateCompare = new Date(date)
            
            return this.mySchedules.filter(ev => {
                const startDate = new Date(ev.startDt)
                return startDate.getYear() == dateCompare.getYear() && startDate.getMonth() == dateCompare.getMonth() && startDate.getDay() == dateCompare.getDay()
            })
        }
    }
}
</script>

<style>
.dash-agenda {
    overflow-y: auto;
    height: 100vh;
}
</style>