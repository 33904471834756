import axios from 'axios'
import store from '@/store'
import authClient from '../authentication/authClient'
import * as userService from './user'
import * as eventService from './event'

const http = axios.create({
    baseURL: process.env.VUE_APP_URL_API
})

http.interceptors.request.use(
    async config => {
      /*
       * Here you can add a header with a JWT token, ensuring it will be
       * sent with ALL your requests.
       */
      const token = 'Bearer ' + await authClient.getToken()
      config.headers.Authorization = token
      return config;
    },
    error => Promise.reject(error),
);

http.interceptors.response.use(
    response => response,
    error => {
      /*
       * Here you can add a central error handling mechanism
       */
      store.dispatch('setErrorMessage', { error: error.response.data })
  
      return Promise.reject(error);
    },
);

export default http

export { userService, eventService }