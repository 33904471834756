import http from '@/services'
import Vue from 'vue'

export const assignUserToEvent = data => {
    return http.post('Event/AssignUserToEvent', data).then(res => {
        Vue.$toast.success("Cafezinho marcado")
        return res
    }).catch(err => {
        if(err.response.status == "401") {
            Vue.$toast.error("Não é possível marcar um cafezinho com si mesmo.")
        }
        else if (err.response.status == "409") {
            Vue.$toast.error("Este horário já está ocupado.")
        }
        throw err
    })
}

export const cancelEvent = data => {
    return http.post('Event/CancelEvent/?eventId=' + data).then(() => {
        Vue.$toast.success("Cafezinho cancelado")
    }).catch(err => {
        console.log('errr',err)
        Vue.$toast.error("Erro ao cancelar cafezinho")
        throw err
    })
}

export const cancelSchedule = data => {
    return http.post('Event/CancelSchedule/?eventId=' + data).then(() => {
        Vue.$toast.success("Horário cancelado")
    }).catch(err => {
        Vue.$toast.error("Erro ao cancelar horário")
        throw err
    })
}