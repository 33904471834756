<template>
  <div :style="{width: '100%'}" class="d-flex flex-column home">
        <v-row class="pa-4">
            <v-col cols="12" md="9" :style="{height: '100vh'}">
                <DashAgendarCafezinho />
            </v-col>
            <v-divider vertical :style="{height: '95vh'}"></v-divider>
            <v-col cols="12" md="3">
                <DashAgenda @updateExecutives="updateUsers" />
            </v-col>
           
        </v-row>
        <div>
            <v-alert class="alert-success" v-if="selectEventOk" type="success" dismissible>Cafezinho marcado com sucesso</v-alert>
            <v-alert class="alert-success" v-if="selectEventNotOk" type="error" dismissible>Erro ao marcar cafezinho</v-alert>
        </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import DashAgendarCafezinho from './DashAgendarCafezinho.vue'
import DashAgenda from './DashAgenda.vue'
export default {
    components: { DashAgendarCafezinho, DashAgenda },
    data() {
        return {
            selectEventOk: false,
            selectEventNotOk: false,
        }
    },
    computed: {
        ...mapState(['currentPage']),
    },
    methods: {
        ...mapActions(['fetchUsers', 'getMyEvents', 'cancelEventById', 'getMySchedules', 'cancelScheduleById', 'fetchUsersByPage']),
        
        updateUsers() {
            this.fetchUsersByPage({page: this.currentPage - 1})
        }
    }
}
</script>

<style>

html, body, .home {
    overflow: hidden !important;
    height: 100vh !important;
}

.v-divider--vertical {
    margin: 0 -0.5px;
}

.tab-items h5 {
    color: rgba(0, 0, 0, 0.6);
    display: inline;
}
</style>