<template>
  <v-app>
    <template v-if="loggedIn && loaded">
        <NovoUsuario v-if="isNewUser" />
        <template v-else>
            <Menu />
            <v-main>
                <v-container>
                    <router-view />
                </v-container>
            </v-main>
        </template>
    </template>
    <template v-else>
        <Load />
    </template>
  </v-app>
</template>

<script>
import Menu from './components/structurals/Menu.vue';
import NovoUsuario from './components/NovoUsuario.vue';
import Load from './components/Load.vue';

import { Accessibility } from 'accessibility/src/main';


var labels = {
    resetTitle: 'Limpar',
    closeTitle: 'Fechar',
    menuTitle: 'Opções de Acessibilidade',
    increaseText: 'aumentar tamanho do texto',
    decreaseText: 'diminuir tamanho do texto',
    increaseTextSpacing: 'aumentar espaçamento do texto',
    decreaseTextSpacing: 'diminuir espaçamento do texto',
    invertColors: 'inverter cores',
    grayHues: 'matizes cinza',
    underlineLinks: 'sublinhar links',
    bigCursor: 'cursor aumentado',
    readingGuide: 'guia de leitura',
    textToSpeech: 'texto para áudio',
    speechToText: 'áudio para texto'
};

var options = { labels: labels };
options.textToSpeechLang = 'pt-BR'; // or any other language
options.speechToTextLang = 'pt-BR'; // or any other language
window.addEventListener('load', function () { new Accessibility(options); }, false);


export default {
    async mounted() {
        if(!this.loggedIn)
        {
            await this.$msalInstance.login();

            this.loggedIn = true;
        }
        
        await this.$store.dispatch('currentUser')
        this.loaded = true
    },
    name: 'App',

    components: {
        Menu, NovoUsuario, Load
    },
    data() {
        return {
            loggedIn: this.$msalInstance.loggedIn(),
            loaded: false
        }
    },
    computed: {
        isNewUser() {
            return this.$store.state.currentUser == null ? true : false
        }
    }
};
</script>